import React from 'react';
import { createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import './resources/bootstrap-v4.5.2/css/bootstrap.min.css';
import './resources/bootstrap/css/bootstrap.min.css';
import './resources/css/vimenca.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
