import React, {useEffect, useState} from 'react'
import {Col, Row } from 'react-bootstrap'
import {Titlesecond, Loader, fetchData} from "../General";
import {Helmet} from "react-helmet";

const EnviosFaciles = props  => {
    let { global, main } = props
    let [ content, setContent ] = useState(null)
    let [ selected, setSelected ] = useState('a_domicilio')
    main = main?.get

    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/faciles.json`, setContent)
    },[])

    content = content?.get

    const block = [
        {
            current: 'a_domicilio'
        },
        {
            current: 'cuenta_bancaria'
        },
        {
            current: 'oficinas'
        }
    ]

    return <>
        {content ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{content.mas_facil.title+' - '+main.main.name}</title>
                <meta name="description" content={content.mas_facil.metadescription} />
                <meta name="keywords" content={content.mas_facil.metakeywords} />
            </Helmet>
            <div className={'mainimg'}>
                <img className={'w-100'} alt={content.mas_facil.title} src={global.domain+'/'+content.mas_facil.panoimg} />
            </div>
            <div className={'container p-5'}>
                <Row className='mb-4'>
                    {block.map( (b,key) => (
                        <Col key={'block-'+key}>
                            <div style={{height:150}} className={ (selected === b.current ? 'bgblue text-white ' : 'bgyellow') + ' d-flex justify-content-center text-center card card-body'} onClick={()=>setSelected(b.current)}>
                                <h5 className={'font-weight-bold m-0'}>{content[b.current].title}</h5>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        { content && <div className={'mt-4'} dangerouslySetInnerHTML={{__html:content[selected].content}}></div> }
                    </Col>
                </Row>

            </div>
        </div>
        : <Loader />
        }
        </>

}

export default EnviosFaciles;
