import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Titlefourth, Titlesecond, ServiciosTransferencia, Loader, fetchData} from "../General";
import {Helmet} from "react-helmet";
import FaqsByCategory from './FaqsByCategory';

const Service2 = (props) => {
    let { global, main } = props
    const id = props.match.params.id || props.id
    let [ content, setContent ] = useState(null)
 
    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/servicio.json?id=${id}`, setContent)
    },[])

    content = content?.get
    let side

    return !content
        ?
        <Container className={'min-vh-100 h-100'} style={{height:'100%'}}>
            <Loader />
        </Container>
        :
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{content.content.title+' - '+main.name}</title>
                <meta name="description" content={content.content.metadescription} />
                <meta name="keywords" content={content.content.metakeywords} />
            </Helmet>
            <div className={'mainimg'}>
                <img className='w-100' alt={content.content.title} src={global.domain+'/'+content.content.mainimg}/>
            </div>
            <div className={'container p-5'}>
                <Row>
                    <Col>
                        <Titlesecond value={content.content.title}/>
                        <div className={'mt-3'} dangerouslySetInnerHTML={{__html:content.content.description}}></div>
                    </Col>
                    {side}
                </Row>
                <Row>
                    {content.enviar !== null ?
                        <Col className={'mt-5'}>
                            <div className={'text-left mb-4'}>
                                <Titlefourth value={content.enviar.question}></Titlefourth>
                                <div dangerouslySetInnerHTML={{__html:content.enviar.awnser}}></div>
                            </div>
                        </Col> : null}

                    {content.recibir !== null ?
                        <Col className={'mt-5'}>
                            <div className={'text-left mb-4'}>
                                <Titlefourth value={content.recibir.question}></Titlefourth>
                                <div dangerouslySetInnerHTML={{__html:content.recibir.awnser}}></div>
                            </div>
                        </Col> : null}
                </Row>
                <Row>
                    <Col>
                        {id === "5" && <div>
                            <ServiciosTransferencia className={'bg-white'} data={main.get.bannericonos} domain={global.domain}/>
                        </div>}
                        <a className={'btn btn-primary mt-5'} href={'/servicios'}>Ver todos nuestros servicios</a>

                    </Col>
                </Row>
            </div>
        </div>
}

export default Service2;
