import React, {useEffect, useState} from 'react'
import {ButtonGroup, Col, Row, ToggleButton} from "react-bootstrap";
import {Titlesecond, Loader, fetchData} from "../General";
import BlockButtons from '../Shared/Buttons/BlockButtons';
import {Helmet} from "react-helmet";

const Voye = props  => {
    let { global, main } = props
    
    const [ contentFr, setContentFr ] = useState(null)
    const [ contentKr, setContentKr ] = useState(null)
    const [ currentLang, setCurrentLang ] = useState('fr')
    const [ currentContentId, setCurrentContentId ] = useState()
    const [ content, setContent ] = useState()

    const loadContent = async () => {
        const frContent = await fetchData(`${global.domain}api-portal/content/view.json?id[]=45&id[]=48&id[]=49`);
        const krContent = await fetchData(`${global.domain}api-portal/content/view.json?id[]=47&id[]=50&id[]=51`)

        setContentFr(frContent);
        setContentKr(krContent);
    }

    useEffect(() => {
        loadContent()
    },[]);

    useEffect(() => {
        setContent(currentLang === 'fr' ? contentFr : contentKr);
    }, [ currentLang, contentFr ]);

    useEffect(() => {
        content && setCurrentContentId(content.content[0].id)
    }, [ content ]);

    const contentTabs = content?.content.map( c => ({ name: c.title, current: c.id }));
    const contentBody = content?.content.find( c => c.id === currentContentId )
    return <>
       { content && contentBody ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{content.content[0].title+' - '+main.get.main.name}</title>
                <meta name="description" content={content.content[0].metadescription} />
                <meta name="keywords" content={content.content[0].metakeywords} />
            </Helmet>
            { content.content[0].panoimg && (
                <div className={'mainimg'}>
                    <img className={'w-100'} alt={content.content[0].title} src={global.domain+'/'+content.content[0].panoimg} />
                </div>
            )}
            <div className={'container p-5'}>
                <Row>
                    <Col>
                        <ButtonGroup className={'mb-3'}>
                            <ToggleButton
                                key={'lang1'}
                                id={'radio-1'}
                                type="radio"
                                variant={'secondary'}
                                name="radio"
                                value={'fr'}
                                checked={currentLang === 'fr'}
                                onChange={() => setCurrentLang('fr')}>
                                Francais
                            </ToggleButton>
                            <ToggleButton
                                key={'lang2'}
                                id={'radio-2'}
                                type="radio"
                                variant={'secondary'}
                                name="radio"
                                value={'cr'}
                                checked={currentLang === 'cr'}
                                onChange={() => setCurrentLang('cr')}>
                                Créole
                            </ToggleButton>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BlockButtons blocks={contentTabs} selected={currentContentId} setSelected={setCurrentContentId}/>
                        <Titlesecond value={contentBody.title}/>
                        { <div className={'mt-4'} dangerouslySetInnerHTML={{__html:contentBody.content}}></div> }
                    </Col>
                </Row>
            </div>
        </div>
        : <Loader />
        }
    </>
}

export default Voye;
