import React from 'react'
import {Col, Row } from 'react-bootstrap'

const BlockButtons = ( {blocks, selected, setSelected} ) => (
    <Row className='mb-4'>
        {blocks.map( (b,key) => (
            <Col key={'block-'+key}>
                <div style={{height:150}} className={ (selected === b.current ? 'bgblue text-white ' : 'bgyellow') + ' d-flex justify-content-center text-center card card-body'} onClick={()=>setSelected(b.current)}>
                    <h5 className={'font-weight-bold m-0'}>{b.name}</h5>
                </div>
            </Col>
        ))}
    </Row>
)

export default BlockButtons;