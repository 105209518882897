import React from 'react'
import {Col, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

import spmPicture from './../resources/img/spm.jpg'

const Spm = props  => {
    let { main } = props
    return (
       <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{'SPM  - '+main.get.main.name}</title>
            </Helmet>
            <div className={'container p-5 text-center'}>
                <Row>
                    <Col>
                        <img alt={"spm"} src={spmPicture} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Spm;
