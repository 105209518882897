import React, {useEffect, useState} from 'react'
import {Col, ButtonGroup, ToggleButton,Row} from 'react-bootstrap'
import {Titlesecond, Loader, fetchData} from "../General";
import {Helmet} from "react-helmet";

const Haiti = (props) => {
    let { global, main, lang: langval } = props
    main = main?.get
    const [ content, setContent ] = useState(null)

    useEffect( () => {
        fetchData(`${global.domain}/api-portal/content/index.json`, setContent)
    },[])

    if(langval === undefined){
        langval = 1
    }
    const [ lang, setLang ]=useState(langval)

    let contenido
    if(content !== null){
        contenido = lang === 1 ? content.content.content_espanol : content.content.content_creole
    }

    return <>
        {content !== null ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{contenido.title+' - '+main.main.name}</title>
                <meta name="description" content={contenido.metadescription} />
                <meta name="keywords" content={contenido.metakeywords} />
            </Helmet>
            <div className={'mainimg'}>
                <img className={'w-100'} alt={contenido.title} src={global.domain+'/'+contenido.panoimg}/>
            </div>
            <div className={'container p-5'}>
                <Row>
                    <Col>
                        <ButtonGroup className={'mb-3'}>
                            <ToggleButton
                                key={'lang1'}
                                id={'radio-1'}
                                type="radio"
                                variant={'secondary'}
                                name="radio"
                                value={1}
                                checked={lang === 1}
                                onChange={(e) => setLang(1)}>
                                Español
                            </ToggleButton>
                            <ToggleButton
                                key={'lang2'}
                                id={'radio-2'}
                                type="radio"
                                variant={'secondary'}
                                name="radio"
                                value={2}
                                checked={lang === 2}
                                onChange={(e) => setLang(2)}>
                                Créole
                            </ToggleButton>
                        </ButtonGroup>

                        <Titlesecond value={contenido.title}/>
                        <div className={'mt-3'} dangerouslySetInnerHTML={{__html:contenido.content}}></div>
                    </Col>
                </Row>
            </div>
        </div>
        : <Loader />
        }
        </>

}

export default Haiti;
