import React from 'react'
import {Container, Row, Pagination} from 'react-bootstrap'
import {Itemnew, Subtitle} from "../General";

class Noticias extends React.Component{
  constructor(props){
      super(props)
      let { global } = props
        this.state={
            isLoadedContent:false,
              news:null,
              domainApi:global.domainApi,
              domain:global.domain,
              apicode:global.api,
              page:1
        }
        this.loadcontent=this.loadcontent.bind(this)
        this.changePagination=this.changePagination.bind(this)
        this.fetchData=this.fetchData.bind(this)
  }

  loadcontent(){
    this.setState({
        isLoadedContent:true
    })
  }

    changePagination(value){
        this.setState({
            page:value
        })
    }

    fetchData(){
        let paramstopass={page:this.state.page}
        paramstopass=btoa(JSON.stringify(paramstopass))
        let url =this.state.domainApi+'newsData/'+this.state.apicode+'/'+paramstopass
        fetch(url).then((response)=>{
            return response.json();
        }).then((json)=>{
            this.setState({
                isLoadedContent:true,
                news:json.news.items,
                pages:json.news.pages
            })
        })
    }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
      this.fetchData()
  }

    render(){

    if(this.state.isLoadedContent){
        let noticias=this.state.news
        let domain=this.state.domain
        let items=[]
        let active=this.state.page
        let numberspages=this.state.pages
        for (let number = 1; number <= numberspages; number++) {
            items.push(
                <Pagination.Item onClick={()=>{this.changePagination(number)}} key={number} active={number === active}>
                    {number}
                </Pagination.Item>,
            );
        }

        const paginationBasic = (
            <div className={'mt-3'}>
                <Pagination>{items}</Pagination>
            </div>
        )


      return (
          <div className="bg-white p-5 text-center border-bottom-yellow">
              <Container>
                  <Subtitle bg={'bgyellow'} value={'Últimas Noticias'}/>
                  <Row className={'mt-3'}>
                      {noticias.map(function(item){
                          return(
                              <Itemnew class={'mt-3'} domain={domain} md={4} key={'news'+item.id} item={item}/>
                          )
                      })}
                  </Row>
                  {paginationBasic}

              </Container>
          </div>
      )
    }else{
      return (
          <Container className={'min-vh-100 h-100'} style={{height:'100%'}}>
            <div className={'min-vh-100 d-flex align-items-center text-center'}>
              <div className="lds-ripple ">
                <div></div>
                <div></div>
              </div>
            </div>
          </Container>
      )
    }
  }
}

export default Noticias;
