import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Titlesecond, Loader} from "../General";
import {Helmet} from "react-helmet";

const Content = (props) => {
    let {global, main, id}=props
    const [content,setContent] = useState(null)

    const loadcontent = () => {
        let paramstopass={idContent:id}
        paramstopass=btoa(JSON.stringify(paramstopass))
        let url =global.domainApi+'contentData/'+global.api+'/'+paramstopass
        fetch(url).then((response)=>{
            return response.json();
        }).then((json)=>{
            setContent(json)
        })
    }

    let contenido
    if(content !== null){
        contenido=content.content
    }
    
    useEffect(()=>loadcontent(),[])
    return <>
        {content !== null ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{contenido.title+' - '+main.get.main.name}</title>
                <meta name="description" content={contenido.metadescription} />
                <meta name="keywords" content={contenido.metakeywords} />
            </Helmet>
            {contenido.panoimg !== null ?
                <div className={'mainimg'}>
                    <img className={'w-100'} alt={contenido.title} src={global.domain+'/'+contenido.panoimg}/>
                </div>
                :
                null
            }

            <div className={'container p-5'}>
                <Row>
                    <Col>
                        <Titlesecond value={contenido.title}/>
                        <div className={'img-space'}>
                            <img src={global.domain+contenido.img}/>
                        </div>
                        <div className={'mt-3'} dangerouslySetInnerHTML={{__html:contenido.content}}></div>
                    </Col>
                </Row>
            </div>
        </div>
        : <Loader />
        }
        </>

}

export default Content;
