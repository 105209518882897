import React, {useEffect, useRef, useState} from 'react'
import * as Bootstrap from 'react-bootstrap'
import Routing from './Routing'
import {Loader, fetchData} from "./General"
import './App.css';

function Contentget(props){
    return <Routing props={props}/>
}

const App = () => {
    let currentdomain = window.location.host
    let protocol = 'https:'
    if (window.location.protocol !== 'https:' && currentdomain !== 'localhost:3000') {
        const href = window.location.href
        const newhref = href.replace("http:", "https:");
        window.location.replace(newhref)
    }

    if(currentdomain === 'vimencawu.com.do'){
        const href = window.location.href
        let newhref = href.replace("http:", "https:");
        newhref = href.replace("vimencawu.com.do", "vimenca.com.do");
        window.location.replace(newhref)
    }

    const allowedDomains = [
        'localhost:3000',
        'staging.vimencawu.com.do',
        'vimenca.com',
        'vimenca.com.do',
        'vimencawu.com.do',
        'www.vimenca.com',
        'www.vimenca.com.do',
        'www.vimencawu.com.do',
        'www.staging.vimencawu.com.do',
    ]

    if(allowedDomains.includes(currentdomain)){
        currentdomain='vimenca.com'
    }

    const [main, setMain] = useState(null)
    const [show, setShow] = useState(false)
    const [showmenu, setShowmenu] = useState(false)
    const [stickheader, setStickheader] = useState(false)

    let headerRef = useRef()

    let global={
        domainApi: protocol+'//cms.'+currentdomain+'/api/v1/',
        domainApiV2: protocol+'//cms.'+currentdomain+'/api-portal/',
        domain: protocol+'//cms.'+currentdomain+'/',
        apicode: '546saf5fd4b654fd6sd54654asda'
    }

    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/main.json`, setMain)
    },[])

    return main !== null
        ?
            <Contentget
                headerRef={headerRef}
                setStickheader={setStickheader}
                stickheader={stickheader}
                setShowmenu={setShowmenu}
                showmenu={showmenu}
                show={show}
                setShow={setShow}
                main={main}
                global={global}
                csfrtoken={main.csfrToken} />
        :
            <Bootstrap.Container className={'min-vh-100 h-100'} style={{height:'100%'}}>
                <Loader />
            </Bootstrap.Container>
}

export default App;
