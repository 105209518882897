import React, {useEffect, useState} from 'react'
import {Col, Row } from 'react-bootstrap'
import {Titlesecond, Loader, fetchData} from "../General";
import {Helmet} from "react-helmet";

const ContentDetailPassingId = props  => {
    let { global, main, id } = props
    const [ content, setContent ] = useState(null)
    main = main?.get
    useEffect( () => {
        fetchData(`${global.domain}/api-portal/content/view.json?id=${id}`, setContent)
    },[])

    return <>
        {content !== null ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{content.content.title+' - '+main.main.name}</title>
                <meta name="description" content={content.content.metadescription} />
                <meta name="keywords" content={content.content.metakeywords} />
            </Helmet>
            <div className={'mainimg'}>
                <img className={'w-100'} alt={content.content.title} src={global.domain+'/'+content.content.panoimg} />
            </div>
            <div className={'container p-5'}>
                <Row>
                    <Col>
                            <Titlesecond value={content.content.title}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'mt-4'} dangerouslySetInnerHTML={{__html:content.content.content}}></div>
                    </Col>
                </Row>

            </div>
        </div>
        : <Loader />
        }
        </>

}

export default ContentDetailPassingId;
