import React from 'react'
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import CanalesBanner from "./../resources/img/canales/banner.jpg";
import Img1 from "./../resources/img/canales/01cellphone.jpg";
import Img2 from "./../resources/img/canales/02domicilio.jpg";
import Img3 from "./../resources/img/canales/03oficinas.jpg";
import Img4 from "./../resources/img/canales/04vimencash.jpg";
import Img5 from "./../resources/img/canales/05servicioalcliente.jpg";
import Img6 from "./../resources/img/canales/06oficina.jpg";
import Img7 from "./../resources/img/canales/07appwu.jpg";
import './../resources/css/canales.css'

const recibir = [
    {
        icon: Img1,
        name: 'DIRECTO A CUENTA',
        texto: 'Inmediatez en el envío y recepción de remesas directo a cuentas bancarias y a menor costo.',
        link: '/servicio/pago-remesas-en-cuenta-bancaria/5'
    },
    {
        icon: Img2,
        name: 'DOMICILIO',
        texto: 'Tus remesas en la comodidad de tu hogar. Tus familiares de fuera solo tienen que indicarlo en el punto Western Union.',
        link: '/remesasadomicilio'
    },
    {
        icon: Img3,
        name: 'VISITA NUESTRAS OFICINAS',
        texto: 'Recibe tus remesas en 2 minutos, en nuestras más de 300 oficinas a nivel nacional.',
        link: '/oficinas'
    },
    {
        icon: Img4,
        name: 'VIMENCASH',
        texto: 'Tu App móvil para recibir y disfrutar tus remesas de forma instantánea. Descárgala y descubre todo lo que puedes hacer.',
        link: '/vimencash'
    },
    {
        icon: Img5,
        name: 'CENTRO DE SERVICIO AL CLIENTE',
        texto: 'Disponibles para ti todo el año con horario extendido y asistencia en el 809-532-7382.',
        link: '/#contacto'
    },
]

const enviar = [
    {
        icon: Img1,
        name: 'ENVÍOS A CUENTAS BANCARIAS',
        texto: 'Tus remesas llegan directamente a una cuenta bancaria en pesos en cuestión de horas.',
        link: '/enviosard'
    },
    {
        icon: Img2,
        name: 'DOMICILIO',
        texto: 'Visita tu punto Western Union más cercano e indícale al representante que quieres enviar tus remesas a domicilio a RD, para que reciban sus chelitos donde quiera que estén.',
        link: '/enviosard'
    },
    {
        icon: Img6,
        name: 'PUNTOS WESTERN UNION',
        texto: 'Visita tu punto para enviar dinero a RD, para que tu gente reciba en ¡2minutos!. Encuentra tu punto más cercano en www.westernunion.com',
        link: '/enviosard'
    },
    {
        icon: Img7,
        name: 'APP WU',
        texto: 'Descárgala y envía tus remesas de la forma más conveniente y tarifa más competitiva.'
    }
]

const ItemList = ({data}) => {
    return (
        <div className={'d-flex align-items-center my-3'} key={data.name}>
            <div>
                <a href={data.link ? data.link : '#'}>
                    <img style={{ width: 'auto', height: 125 }} alt={data.name} src={data.icon} />
                </a>
            </div>
            <div className='pl-4 w-75'>
                <a href={data.link ? data.link : '#'}>
                    <p className='m-0'><strong>{data.name}:</strong> {data.texto}</p>
                </a>
            </div>
        </div>
    )
}
const Canales = props  => {
    let { main } = props
    main = main?.get
    return <>
            <div className={'canales border-bottom-yellow pb-5'}>
                <Helmet>
                    <title>{ 'Canales de pago - '+ main.main.name }</title>
                </Helmet>
                <Row>
                    <Col xs={12} md={6}>
                        <img className={'w-100'} alt={'Canales de pago'} src={CanalesBanner} />
                    </Col>
                    <Col xs={12} md={6} className='d-flex align-items-center'>
                        <div className=' text-center p-5'>
                            <h2>ENVÍA Y RECIBE TUS REMESAS DE LA FORMA</h2>
                            <h2><strong>MÁS FÁCIL, RÁPIDA Y SEGURA</strong></h2>

                            <h3 className='mt-5'>CON CANALES CREADOS PARA TI, QUE SE AJUSTAN A TUS NECESIDADES</h3>
                        </div>
                    </Col>
                </Row>
                <Container className='pb-5'>
                    <Row>
                        <Col xs={12} md={6}>
                            <h3 className='text-center text-md-left mb-5'><strong>PARA RECIBIR TUS REMESAS EN RD</strong></h3>
                            {recibir.map(item => <ItemList data={item}/>)}
                        </Col>
                        <Col xs={12} md={6}>
                            <h3 className='text-center text-md-left mb-5'><strong>DESDE EL EXTERIOR PARA ENVIAR A RD</strong></h3>
                            {enviar.map(item => <ItemList data={item}/>)}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>

}

export default Canales;
