import React, {useEffect, useState} from 'react'
import {Loader} from "../General";
import * as Bootstrap from "react-bootstrap";

export const IframeOficinas = (props) => {
    const [loading,setLoading] = useState(false)
    const [height,setHeight]=useState(0)

    const loadingVimen = () => {
        let windowheight = window.innerHeight
        let headerheight=props.headerRef.current.offsetHeight
        setLoading(true)
        setHeight(windowheight-headerheight)
    }

    useEffect(()=>loadingVimen(),[])

    return loading
        ?
        <div className={'bg-white'}>
            <div
                dangerouslySetInnerHTML={{ __html: "<iframe frameborder='0' width='100%' height='"+height+"' src='/map-mod/index.html' />"}}
            />
        </div>
        :
        <Bootstrap.Container className={'min-vh-100 h-100'} style={{height:'100%'}}>
            <Loader />
        </Bootstrap.Container>
}

