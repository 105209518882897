import { Modal } from 'react-bootstrap'
import uno from "./../resources/img/35aniversario/images/uniendo-corazones_03.jpg";
import slide from "./../resources/img/35aniversario/images/uniendo-corazones_06.jpg";
import inocencia from "./../resources/img/35aniversario/images/uniendo-corazones_09.jpg";
import gustavo from "./../resources/img/35aniversario/images/uniendo-corazones_11.jpg";
import fatima from "./../resources/img/35aniversario/images/uniendo-corazones_13.jpg";
import miriam from "./../resources/img/35aniversario/images/uniendo-corazones_15.jpg";
import miriam_modal from "./../resources/img/35aniversario/miriam.jpg";
import fatima_modal from "./../resources/img/35aniversario/fatima.jpg";
import gustavo_modal from "./../resources/img/35aniversario/gustavo.jpg";
import inocencia_modal from "./../resources/img/35aniversario/inocencia.jpg";
import { Helmet } from "react-helmet";
import { useState } from 'react';

const Aniversario = (props) => {
    let { main } = props;
    const [ show, setShow ] = useState(false);
    const [ content, setContent ] = useState();
    const testimonios = [
        {
            testimonio: 'Durante los 12 años que tengo utilizando Remesas Vimenca, estoy muy fascinado con la buena atención y servicio de los empleados.',
            name: 'Pedro Pablo Mosquea',
            anos: 12
        },
        {
            testimonio: 'Me he sentido muy bien desde que utilizo los servicios de Remesas Vimenca, porque son muy eficientes. Siempre pido a mi familia que me envíen por Vimenca.',
            name: 'Águeda Cortoreal',
            anos: 22
        },
        {
            testimonio: 'Tengo más de 10 años utilizando sus servicios. Me he sentido complacida al 100%, porque su trabajo ha sido impecable.”',
            name: 'Rubí Peguero',
            anos: 10
        }
    ];

    const colaboradores = [
        {
            img: inocencia,
            name: 'Inocencia Concepción',
            position: 'Soporte Técnico Coorporativo',
            anos: 41,
            link: inocencia_modal
        },
        {
            img: gustavo,
            name: 'Gustavo Romano',
            position: 'Supervisor de Seguridad',
            anos: 33,
            link: gustavo_modal
        },
        {
            img: fatima,
            name: 'Fátima Serrano',
            position: 'Director Financiero',
            anos: 29,
            link: fatima_modal
        },
        {
            img: miriam,
            name: 'Miriam Quezada',
            position: 'Gerente Zona Norte',
            anos: 29,
            link: miriam_modal
        }
    ];

    return <>
        <Helmet>
            <title>{'Más de 35 años de historia con nosotros - '+ main.get?.name}</title>
            <meta name="description" content={'A lo largo de estas décadas, detrás de cada transacción, hemos estado presente siendo ese enlace entre el dominicano en el exterior con su tierra.'} />
            <meta name="keywords" content={'35 anos de historia, aniversario'} />
        </Helmet>

        <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Body>
                {content}
            </Modal.Body>
        </Modal>

        <div className={'bg-white'}>
            <div className={'bgblue py-5 px-3 text-center'}>
                <h1 className="font-weight-bolder text-white main-heading">Uniendo corazones</h1>
            </div>
            <div className="container px-4 py-5 ">
                <div className="row align-items-center h-100">
                    <div className="col-12 col-md-6 col-lg-5 py-5">
                        <img src={uno} style={{ minWidth: 200, maxWidth: 700}} className={'w-100'} />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="lead text-blue text-justify" style={{fontSize: '150%'}}>
                            <p>A lo largo de estas décadas, detrás de cada
                            transacción, hemos estado presente siendo ese
                            enlace entre el dominicano en el exterior con
                            su tierra.</p>
                            <p>Reafirmamos nuestro compromiso de hacer
                            realidad cada entrega, demostrando que el
                            amor trasciende fronteras, uniendo generaciones
                            para construir un futuro mejor.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bgblue py-5 px-4 text-center'}>
                <h1 className="mt-4 mb-2 font-weight-bolder text-white" style={{fontSize: '200%'}}>Nuestros clientes se unen a la celebración</h1>
                <div className="container mb-4">
                    <div className="row" >
                        { testimonios.map(test =><div className={'col-12 col-md-4 d-flex align-content-around'}><div className="card mt-4 p-2">
                            <div className="card-body lead text-left text-gray py-4 d-flex justify-content-between flex-column">
                                <p>"{test.testimonio}"</p>
                                <div>
                                    <p className="mt-4 mb-0"><strong>{test.name}</strong></p>
                                    <p className="m-0">{test.anos} utilizando el servicio</p>
                                </div>
                            </div>
                        </div></div>)}
                    </div>
                </div>
            </div>
            <div className="container py-5 px-4 text-center">
                <h1 className="py-5 font-weight-bolder text-blue" style={{fontSize: '260%'}}>Más de 35 años de historia con nosotros...</h1>
            </div>
            <img src={slide} alt={'Más de 35 años de historia'}/>
            <div className="container px-4 py-5 text-center">
                <h1 className="py-5 font-weight-bolder text-blue" style={{fontSize: '260%'}}>Algunos colaboradores nos hablan de su trayectoria</h1>
                <div className="row" >
                    { colaboradores.map(cola =><div className={'col-12 col-md-3'}>
                        <div className="card border-0 mt-5 mt-md-0" style={{backgroundColor: '#f5f5f5', borderRadius: '0.35rem'}}>
                        <img src={cola.img} className="card-img-top" alt={cola.name} />
                        <div className="card-body text-left text-gray">
                            <p className="m-0" style={{fontSize: '150%'}}><strong>{cola.name}</strong></p>
                            <p className="m-0">{cola.position}</p>
                            <p className="m-0">{cola.anos} años de servicio</p>
                            <p className="mt-4 mb-0"><button className={'border-0 bg-transparent px-0'} onClick={() => { setContent(<img alt={cola.name} src={cola.link} /> ); setShow(true) }}>Leer más</button></p>
                        </div>
                    </div></div>)}
                </div>
            </div>
        </div>
    </>
}

export default Aniversario;