import React, {useEffect, useState} from 'react'
import {Col, Row } from 'react-bootstrap'
import {Titlesecond, Loader, fetchData} from "../General";
import BlockButtons from "../Shared/Buttons/BlockButtons"
import {Helmet} from "react-helmet";

const SendingRD = props  => {
    let { global, main } = props
    let [ content, setContent ] = useState(null)
    let [ selected, setSelected ] = useState('a_domicilio')
    let [ blocks, setBlocks ] = useState()
    main = main?.get

    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/sending-rd.json`, setContent)
    },[])

    useEffect( () => {
        const contentVal = content?.get
        contentVal && setBlocks([
            {
                current: 'a_domicilio',
                name: contentVal?.a_domicilio?.title
            },
            {
                current: 'cuenta_bancaria',
                name: contentVal?.cuenta_bancaria?.title
            },
            {
                current: 'oficinas',
                name: contentVal?.oficinas?.title
            }
        ])
    },[content])

    return <>
        {blocks ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{content?.get?.mas_facil?.title+' - '+main.main.name}</title>
                <meta name="description" content={content?.get?.mas_facil?.metadescription} />
                <meta name="keywords" content={content?.get?.mas_facil?.metakeywords} />
            </Helmet>
            <div className={'mainimg'}>
                <img className={'w-100'} alt={content?.get?.mas_facil?.title} src={global.domain+'/'+content?.get?.mas_facil.panoimg} />
            </div>
            <div className={'container p-5'}>
                <BlockButtons blocks={blocks} selected={selected} setSelected={setSelected}/>
                <Row>
                    <Col>
                        { <div className={'mt-4'} dangerouslySetInnerHTML={{__html: content?.get[selected].content}}></div> }
                    </Col>
                </Row>

            </div>
        </div>
        : <Loader />
        }
        </>

}

export default SendingRD;
