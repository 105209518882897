import React, {useState} from 'react'
import { Form, Row, Col, Button, Container, Alert } from 'react-bootstrap';
import InputForm from './InputForm';
import { FieldsPropertiesType, TypeOfInputField } from './types';
import SingleCheckboxForm from './SingleCheckboxForm';
import SelectForm from './SelectForm';

export interface FormBuilderType {
    fieldsToForm: Array<FieldsPropertiesType>,
    onSubmit: (fields: any) => any
}

interface SetValuesFieldsType {
    label: string,
    value: string | number | boolean,
    type: TypeOfInputField
}

type BuiltFieldsType = Record<string, any>;

const AssignFieldTypeByType = (props: {properties: FieldsPropertiesType, fields: BuiltFieldsType, setValueFields: (params: SetValuesFieldsType) => void, isFormValidated: boolean }): any => {
    const { properties, fields, setValueFields, isFormValidated } = props;
    if (properties.type === 'checkbox') {
        return (
            <SingleCheckboxForm
                fields={fields}
                properties={properties}
                isFormValidated={isFormValidated}
                onChange={(e) => {
                    setValueFields({ label: properties.id, value: e.target.checked, type: properties.type });
                }} 
            />
        );
    } else if (properties.type === 'select') {
        return (
            <SelectForm 
                fields={fields}
                properties={properties}
                isFormValidated={isFormValidated}
                onChange={(e) => {
                    const value: string = Array.from(e.target.selectedOptions).map((op: any) => op.value).join(', ');
                    setValueFields({ label: properties.id, value, type: properties.type });
                }} 
            />
        )
    } else if (properties.type === 'separator') {
            return (
                <div className={'text-center mb-5 mt-3'}>
                    <h3 className={'lead'}>{properties.label}</h3>
                    <hr />
                </div>
            )
    
    } else {
        return (
            <InputForm
                fields={fields}
                properties={properties}
                isFormValidated={isFormValidated}
                onChange={(e) => {
                    setValueFields({label: properties.id, value: properties.type === 'file' ? e.target.files : e.target.value, type: properties.type });
                }} 
            />
        );
    }
}
export default function FormBuilder(props: FormBuilderType): JSX.Element {
    const { fieldsToForm, onSubmit } = props;
    const [ validated, setValidated ] = useState(false);
    const [ isSent, setIsSent ] = useState(false);
    const [ disabledWhileSending, setDisabledWhileSending ] = useState(false);
    const buildFields: BuiltFieldsType = {};
    
    fieldsToForm.forEach(f => buildFields[f.id] = null);
    const [fields, setFields] = useState(buildFields);

    const setValueFields = (params: SetValuesFieldsType) => {
        let { label, value, type } = params;
        const newFields = {...fields};
        newFields[label] = type === 'file' ? value : String(value);
        setFields(newFields);
    }

    const sendEmail = (fields: any) => {
        setDisabledWhileSending(true);
        setValidated(true);
        const allFieldValid = fieldsToForm
            .filter( field => field.required && field.isValid )
            .every( field => {
                return field.isValid(fields[field.id]) 
            });
        if (allFieldValid) {
            onSubmit(fields);
            setIsSent(true);
        }
        setTimeout(() => {
            setDisabledWhileSending(false);
        }, 3500);
    }
    return (
        <Form validated={validated} className='py-5'>
            <Container>
                {isSent ?
                    (
                        <Alert variant={'info'}>Muchas gracias!. El mensaje ha sido recibido.</Alert>
                    )
                :
                    (<>
                        <Row>
                            {fieldsToForm.map((field, idx) => {
                                let colVal = 6;
                                switch (field.style) {
                                    case 'full-width':
                                        colVal = 12;
                                    break;
                                    case 'quarter-width':
                                        colVal = 4;
                                    break;
                                } 
                                return (
                                    <Col key={idx} xs={colVal}>
                                        { <AssignFieldTypeByType isFormValidated={validated} fields={fields} properties={field} setValueFields={setValueFields}/> }
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row>
                            <Col>
                                <Button disabled={disabledWhileSending} className='mt-3' type="button" onClick={()=> sendEmail(fields)}>Enviar</Button>
                            </Col>
                        </Row>
                    </>)
                }
            </Container>
        </Form>
    )
}