import React, { useSearchParams } from 'react'
import { Container, Card, Accordion, Button} from 'react-bootstrap'
import { Subtitle, Titlefourth, fetchData } from './../General.js'

class Faqs extends React.Component{
    constructor(props){
    super(props)
    const { global } = props
    const id = new URLSearchParams(window.location.search).get('id');
    this.state = {
        isLoadedContent: false,
        content: null,
        domainApi: global.domainApi,
        domain: global.domain,
        apiCode: global.apicode,
        localidades: [],
        page: 1,
        faqs: [],
        activeKey: parseFloat(id) || 1
    }
    this.setContent = this.setContent.bind(this)
    }

    componentDidMount() {
        const { domainApi, apiCode } = this.state
        fetchData(`${domainApi}faqsData/${apiCode}`, this.setContent)
    }

    setContent(value) {
        this.setState(
            {
                isLoadedContent: true,
                faqs: value.faqs
            }
        )
    }

    render(){
        if(this.state.isLoadedContent){
            const {activeKey, faqs } = this.state
            return (
                <div className={'bg-white text-center p-5 border-bottom-yellow'}>
                    <div className={'container'}>
                        <Subtitle bg={'bgyellow mb-3'} value={'Preguntas Frecuentes'}/>
                        <Accordion className={'mt-5'} defaultActiveKey={activeKey} >
                            { faqs.map(item => (
                                <Accordion.Item eventKey={item.id}>
                                    <Accordion.Header>
                                        <span id={`faq-${item.id}`} className={'text-black'}>{item.name}</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {item.faqs.map(function(itemf, idx){
                                            return(
                                                <div key={`item${idx}`} className={'text-left mb-4'}>
                                                    <Titlefourth value={itemf.question}></Titlefourth>
                                                    <div dangerouslySetInnerHTML={{__html:itemf.awnser}}></div>
                                                </div>
                                            )
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                                )
                            )}
                        </Accordion>
                    </div>
                </div>
            )
        } else {
              return (
                  <Container className={'min-vh-100 h-100'} style={{height:'100%'}}>
                    <div className={'min-vh-100 d-flex align-items-center text-center'}>
                      <div className="lds-ripple ">
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </Container>
              )
        }
  }
}

export default Faqs;
