import React from 'react'
import { Form } from 'react-bootstrap';
import { AllFieldPropsType } from './types';

function InputForm(props: AllFieldPropsType): any{
    const {label, id, required, type, placeholder, invalidMessage, isValid } = props.properties;
    const valid = isValid(props.fields[id]);
    const message = !valid ? invalidMessage : undefined;
    return (
        <Form.Group className="mb-3" controlId={id}>
            <Form.Label>{label}</Form.Label>
            <Form.Control onChange={props.onChange} required={required} type={type} placeholder={placeholder} isInvalid={props.isFormValidated ?!valid : undefined} />
            <Form.Control.Feedback type={ !valid ? 'invalid' : 'valid' }>
                {message}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default InputForm;