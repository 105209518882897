import React from 'react'
import Form  from 'react-bootstrap/Form';
import { AllFieldPropsType, SelectBoxPropertiesType } from './types';

function SelectForm(props: AllFieldPropsType): any{
    const {label, id, required, options, placeholder, invalidMessage, isValid } = props.properties as SelectBoxPropertiesType;
    const valid = isValid(props.fields[id]); 
    const message = !valid && invalidMessage;
    return (
        <Form.Group className="mb-3" controlId={id}>
            <Form.Label>{label}</Form.Label>
            <Form.Select 
                className={'form-control'}
                onChange={props.onChange} 
                required={required} aria-label={placeholder}
                defaultValue={'label'}
                isInvalid={props.isFormValidated ? !valid : undefined}
            >   
                <option value={'label'} disabled>{label}</option>
                {options.map((op, idx) => <option key={idx} value={op.label}>{op.label}</option>)}
            </Form.Select>
            <Form.Control.Feedback type={ valid ? 'valid' : 'invalid' }>
                {message}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default SelectForm;