import React, {useEffect, useRef, useState} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import {Titlesecond, Loader, fetchData} from "../General";
import FaqsByCategory from './FaqsByCategory'
import Img from './../resources/img/institucional/Mesa-de-trabajo-1.jpg'
import Abrazos from './../resources/img/institucional/abrazos.jpg'
import Besos from './../resources/img/institucional/besos.jpg'
import Corazon from './../resources/img/institucional/corazon.jpg'
import Icon1 from './../resources/img/institucional/domicilio.png'
import Icon2 from './../resources/img/institucional/directocuenta.png'
import Icon3 from './../resources/img/institucional/vimencash.png'
import Icon4 from './../resources/img/institucional/oficinas.png'
import title1 from './../resources/img/institucional/title1.png'
import title2 from './../resources/img/institucional/title2.png'
import audio from './../resources/img/institucional/MasterVimenca_1.mp3'
import { Helmet } from "react-helmet";

const Institucional = (props) => {
    let { global, main } = props
    const [content, setContent] = useState(null);
    const [show, setShow] = useState(false);
    const [showFaqs, setShowFaqs] = useState('none');
    const [modalImg, setModalImg] = useState(false);
    const width = '100%';
    const [height, setHeight] = useState();

    const videocontainer = useRef();

    const video = 'https://www.youtube.com/embed/1oL9sH7k6fY'

    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/view.json?id=40`, setContent)
    },[]);

    useEffect(() => {
        const ratio = 16/9
        setHeight(videocontainer?.current?.offsetWidth / ratio)
      }, [content]
    );

    const settingModalImage = (details) => {
        const {name, image} = details;
        setModalImg({name, image})
        setShow(true)
    }

    const contentValue = content;
    return <>
        {contentValue ?
        <div className={'bg-white border-bottom-yellow'}>
            <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Body>
                    <img className={'w-100'} alt={modalImg.name} src={modalImg.image} />
                </Modal.Body>
            </Modal>
            
            <Helmet>
                <title>{contentValue.content.title+' - '+ main.get?.name}</title>
                <meta name="description" content={contentValue.content.metadescription} />
                <meta name="keywords" content={contentValue.content.metakeywords} />
            </Helmet>
            <div className={'mainimg'}>
                <img className={'w-100'} alt={contentValue.content.title} src={Img} />
            </div>
            <div className={'container p-5'}>
                {video && 
                    <Row>
                        <Col>
                        <img style={{height:30, width: 'auto'}} alt={'TV'} src={title2} />
                        <div>
                            <div ref={videocontainer} className={'bgblack p-3 my-3'} dangerouslySetInnerHTML={{
                                __html:`<iframe width="${width}" height="${height}"  src="${video}" title="${contentValue.content.title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                            }}>
                                
                            </div>
                        </div>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <img style={{height:30, width: 'auto'}} alt={'Gráfica'} src={title1} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <img onClick={() => settingModalImage({name: 'Abrazos', image: Abrazos})} className={'w-100'} alt={'Abrazoz'} src={Abrazos} />
                    </Col>
                    <Col xs={12} md={4}>
                        <img onClick={() => settingModalImage({name: 'Besos', image: Besos})} className={'w-100'} alt={contentValue.content.title} src={Besos} />
                    </Col>
                    <Col xs={12} md={4}>
                        <img onClick={() => settingModalImage({name: 'Corazon', image: Corazon})} className={'w-100'} alt={contentValue.content.title} src={Corazon} />
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col className='text-center blue'>
                        <Titlesecond value={'Conoce nuestros canales'}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <a href='/remesasadomicilio'>
                        <img className={'w-100'} alt={contentValue.content.title} src={Icon1} />
                        </a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a href='/servicio/pago-remesas-en-cuenta-bancaria/5'>
                        <img className={'w-100'} alt={contentValue.content.title} src={Icon2} />
                        </a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a href='/vimencash'>
                        <img className={'w-100'} alt={contentValue.content.title} src={Icon3} />
                        </a>
                    </Col>
                    <Col xs={12} md={3}>
                        <a href='/oficinas'>
                        <img className={'w-100'} alt={contentValue.content.title} src={Icon4} />
                        </a>
                    </Col>
                </Row>

                <Row className="justify-content-between">
                    <Col xs={12} md={4}>
                        <button onClick={()=>setShowFaqs(showFaqs === 'none' ? 'block' : 'none')} className={'py-2 px-4 float-md-right btn text-white bgblue'} >Preguntas Frecuentes</button>
                    </Col>
                    <Col xs={12} md={4}>
                        <audio src={audio} controls />
                    </Col>
                </Row>      
                <Row style={{display: showFaqs}}>
                    <Col>
                        <FaqsByCategory id={7} global={global}/>
                    </Col>
                </Row>          

            </div>
        </div>
        : <Loader />
        }
        </>

}

export default Institucional;
