import React from 'react'
import {Helmet} from "react-helmet";
import './../resources/css/diaspora.css'

import mainImg from './../resources/img/diaspora/main.jpg'
import secondImg from './../resources/img/diaspora/main2.png'
import frontImg from './../resources/img/diaspora/diaspora-frente.png'
import logos from './../resources/img/diaspora/logos.jpg'
import footer from './../resources/img/diaspora/footer.jpg'
import min1 from './../resources/img/diaspora/min1.png'
import min2 from './../resources/img/diaspora/min2.png'
import min3 from './../resources/img/diaspora/min3.png'
import min4 from './../resources/img/diaspora/min4.png'
import rd from './../resources/img/diaspora/rd.png'
import whole from './../resources/img/diaspora/whole.png'
import materials1 from './../resources/img/diaspora/materials1.png'
import materials2 from './../resources/img/diaspora/materials2.png'

const ImgFramed = ({src, className}) => <div className={`p-2 background-white ${className}`}><img src={src}/></div>
const BlockText = ({children, variant, className}) => <div className={`m-5 px-5 py-4 bg-${variant} text-center ${className}`}>{children}</div>

const Diaspora = (props) => {

    return <>
        <div id={'diaspora-container'} style={{backgroundImage:`url(${mainImg})`}}>
            <Helmet>
                <title>{'Monumento a la Diáspora Dominicana.'}</title>
            </Helmet>
            <div className='main-top py-5'>
                <div className='container py-5'>
                    <div className='row py-5'>
                        <div className='col-12 col-md-6 py-5'>
                            <h1 className='bold d-block mb-5'>Plaza de la<br/>Diáspora Dominicana.</h1>
                            <img src={logos} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='second-top py-5' style={{backgroundImage:`url(${secondImg})`}}>
                <div className='container py-5'>
                    <div className='row py-5 justify-content-end'>

                        <div className='col-12 col-md-6 col-lg-7 py-5 my-3'>
                            <h2 className='d-block text-center py-5'>"Un homenaje a los sueños y a la esperanza de la Diáspora Dominicana".</h2>
                        </div>
                    </div>
                </div>
            

            <div className='container'>
                <ImgFramed className={'mt-5'} src={frontImg}/>
                <div className='video my-5'>
                    <div dangerouslySetInnerHTML={
                        {__html:'<iframe width="100%" height="600" src="https://www.youtube.com/embed/nf2L7YQviNQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'}
                    }></div>
                </div>
                <BlockText variant={'light-blue'}>
                    <h5 className='m-0'><strong>Grupo Vimenca y la Alcadía del Distrito Nacional</strong> ponen a la
                    disposición del público la nueva <strong>"Plaza de la Diáspora Dominicana"</strong>, un
                    espacio urbano que tiene como punto central y focal el <strong>Monumento a la
                    Diáspora Dominicana</strong> que muestra el recorrido del dominicano que lleva
                    <strong>su corazón y su tierra</strong> a cualquier rincón del mundo.</h5>
                </BlockText>
                <div className='row'>
                    <div className='col'>
                        <ImgFramed src={min1}/>
                    </div>
                    <div className='col'>
                        <ImgFramed src={min2}/>
                    </div>
                    <div className='col'>
                        <ImgFramed src={min3}/>
                    </div>
                    <div className='col'>
                        <ImgFramed src={min4}/>
                    </div>
                </div>

                <BlockText variant={'yellow'}>
                    <h5>
                        7 figuras representan a los dominicanos por el mundo con un punto en
                        común, su corazón conectado a su tierra: doctora, estudiante, obrero de
                        la construcción, modelo, atleta, niñera y personal que trabaja en un 
                        almacén.
                    </h5>
                    <h5>
                        Estas figuras representan al dominicano donde se encuentre, ahí se
                        refleja nuestro calor, nuestro deseo de darlo todo y de vivir al máximo,
                        nuestra esencia. También nos mantenemos unidos a nuestra familia y a
                        nuestro pueblo, aunque no sea físicamente. Por eso, esa solidaridad que
                        albergamos en el corazón es como un cordón umbilical que sigue
                        conectado a nuestra gente. Siempre ponemos en alto el nombre de
                        nuestra amada República Dominicana.
                    </h5>
                </BlockText>
                <div className='px-5'>
                    <ImgFramed className={'mb-5'} src={rd}/>
                    <ImgFramed src={whole}/>
                </div>

                <BlockText variant={'light-blue'}>
                    <h5>
                        La plaza a la diáspora fue construida con alto esmero, y muestra de esto
                        es la selección tanto de materiales como toda la ambientación
                        completamente dominicanos. Toda la flora sembrada en la plaza fue una
                        selección de plantas endémicas, así como los materiales seleccionados
                        fueron construidos por manos dominicana para honrar a la diáspora.
                    </h5>
                    <h5>
                        Las acera incluyen pavimentos podotáctiles para las personas con
                        discapacidad visual y se aumentaron y ajustaron rampas y aceras para
                        hacerlas adecuadas al tránsito de peatones, convirtiéndolo en un espacio
                        para el sano esparcimiento para toda la familias.
                    </h5>
                </BlockText>

                <div className='row mb-5'>
                    <div className='col'>
                        <ImgFramed src={materials1}/>
                    </div>
                    <div className='col'>
                        <ImgFramed src={materials2}/>
                    </div>
                </div>

                <BlockText variant={'yellow'}>
                    <h5 className='m-0'><strong>Mural a la Diáspora Dominicana "Victor Méndez Capellán y Finetta
                    Saba de Méndez"</strong></h5>
                    <h5>Este mural refleja a esos dominicanos destacados en el extranjero que
                        día a día enaltencen nuestra bandera. En esta primera fase en
                        colaboración con el Instituto de Dominicanos y Dominicanas en el
                        Exterior, INDEX, se han identificado un grupo de compatriotas que se
                        han destacado por su labor en distintas ramas en el extranjero.
                    </h5>
                    <h5>
                        De igual forma, se exalta la fidelidad de los clientes de Vimenca,
                        destacando un grupo de ellos que por más de 30 años, se mantienen de
                        forma constante compartiendo ese granito de arena y apoyo
                        incondicional a sus seres queridos en su tierra.
                    </h5>
                    <table className='my-5 table table-borderless text-left'>
                        <tr>
                            <td><strong>Área de la plaza:</strong></td>
                            <td>2,680 m2</td>
                        </tr>
                        <tr>
                            <td><strong>Diseño Urbano:</strong></td>
                            <td>
                                Arq. Marcos Barinas<br/>
                                Arq. Elsa Taveras
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Paisajismo:</strong></td>
                            <td>
                                Arq. Marcos Barinas<br/>
                                Arq. Sheila Arias
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <strong>Conceptualización y diseño del Monumento:</strong>
                            </td>
                            <td>
                                Ezequiel Taveras<br/>
                                Artista
                            </td>
                        </tr>
                    </table>
                </BlockText>
                </div>
            </div>
            <div className='footer container'>
                <img width={'100%'} src={footer} />
            </div>
        </div>
    </>
}

export default Diaspora;
