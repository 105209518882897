export const monedas: Array<{value: string, label: string}> = [
    {
        value: 'Pesos dominicanos',
        label: 'Pesos dominicanos',
    },
    {
        value: 'Dólares Estadounidenses',
        label: 'Dólares Estadounidenses',
    }
];

export const bancos: Array<{value: string, label: string}> = [
    {
      value: "0",
      label: "ASOCIACION BONAO DE AHORROS Y PRESTAMOS"
    },
    {
      value: "1",
      label: "ASOCIACION CIBAO DE AHORROS Y PRESTAMOS"
    },
    {
      value: "2",
      label: "ASOCIACION DUARTE DE AHORROS Y PRESTAMOS"
    },
    {
      value: "3",
      label: "ASOCIACION LA NACIONAL DE AHORROS Y PRESTAMOS"
    },
    {
      value: "4",
      label: "ASOCIACION LA VEGA REAL DE AHOR Y PRESTAMOS"
    },
    {
      value: "5",
      label: "ASOCIACION MAGUANA DE AHORROS Y PRESTAMOS"
    },
    {
      value: "6",
      label: "ASOCIACION MOCANA DE AHORROS Y PRESTAMOS"
    },
    {
      value: "7",
      label: "ASOCIACION PERAVIA DE AHORROS Y PRESTAMOS"
    },
    {
      value: "8",
      label: "ASOCIACION POPULAR DE AHORROS Y PRESTAMOS"
    },
    {
      value: "9",
      label: "ASOCIACION ROMANA DE AHORROS Y PRESTAMOS"
    },
    {
      value: "10",
      label: "BANCO AGRICOLA DE LA REPUBLICA DOMINICANA"
    },
    {
      value: "11",
      label: "BANCO AHORRO Y CREDITO ADOPEM"
    },
    {
      value: "12",
      label: "BANCO AHORRO Y CREDITO BANCOTUI"
    },
    {
      value: "13",
      label: "BANCO AHORRO Y CREDITO COFACI"
    },
    {
      value: "14",
      label: "BANCO AHORRO Y CREDITO CONFISA"
    },
    {
      value: "15",
      label: "BANCO AHORRO Y CREDITO DEL CARIBE (BACC)"
    },
    {
      value: "16",
      label: "BANCO AHORRO Y CREDITO EMPIRE"
    },
    {
      value: "17",
      label: "BANCO AHORRO Y CREDITO FIHOGAR"
    },
    {
      value: "18",
      label: "BANCO AHORRO Y CREDITO FONDESA"
    },
    {
      value: "19",
      label: "BANCO AHORRO Y CREDITO GRUFICORP"
    },
    {
      value: "20",
      label: "BANCO AHORRO Y CREDITO JMMB BANK"
    },
    {
      value: "21",
      label: "BANCO AHORRO Y CREDITO UNION"
    },
    {
      value: "22",
      label: "BANCO ATLANTICO DE AHORRO Y CREDITO"
    },
    {
      value: "23",
      label: "BANCO DE RESERVAS DE LA REPUBLICA DOMINICANA"
    },
    {
      value: "24",
      label: "BANCO DOMINICANO DEL PROGRESO"
    },
    {
      value: "25",
      label: "BANCO MULTIPLE ACTIVO DOMINICANA"
    },
    {
      value: "26",
      label: "BANCO MULTIPLE ADEMI"
    },
    {
      value: "27",
      label: "BANCO MULTIPLE BDI"
    },
    {
      value: "28",
      label: "BANCO MULTIPLE BELLBANK"
    },
    {
      value: "29",
      label: "BANCO MULTIPLE BHD LEON"
    },
    {
      value: "30",
      label: "BANCO MULTIPLE CARIBE INTERNACIONAL"
    },
    {
      value: "31",
      label: "BANCO MULTIPLE DE LAS AMERICAS"
    },
    {
      value: "32",
      label: "BANCO MULTIPLE LAFISE"
    },
    {
      value: "33",
      label: "BANCO MULTIPLE LOPEZ DE HARO"
    },
    {
      value: "34",
      label: "BANCO MULTIPLE PROMERICA DE LA RD"
    },
    {
      value: "35",
      label: "BANCO MULTIPLE SANTA CRUZ"
    },
    {
      value: "36",
      label: "BANCO MULTIPLE VIMENCA"
    },
    {
      value: "37",
      label: "BANCO NACIONAL DE LAS EXPORTACIONES (BANDEX)"
    },
    {
      value: "38",
      label: "BANCO POPULAR DOMINICANO"
    },
    {
      value: "39",
      label: "BANESCO BANCO MULTIPLE"
    },
    {
      value: "40",
      label: "BONANZA BANCO DE AHORRO Y CREDITO"
    },
    {
      value: "41",
      label: "CITIBANK"
    },
    {
      value: "42",
      label: "CORPORACION DE CREDITO LEASING CONFISA"
    },
    {
      value: "43",
      label: "CORPORACION DE CREDITO MONUMENTAL"
    },
    {
      value: "44",
      label: "CORPORACION DE CREDITO NORDESTANA DE PRESTAMOS"
    },
    {
      value: "45",
      label: "CORPORACION DE CREDITO OFICORP"
    },
    {
      value: "46",
      label: "CORPORACION DE CREDITO REIDCO"
    },
    {
      value: "47",
      label: "MOTOR CREDITO BANCO DE AHORRO Y CREDITO"
    },
    {
      value: "48",
      label: "OPTIMA CORPORACION DE CREDITO"
    },
    {
      value: "49",
      label: "THE BANK OF NOVA SCOTIA"
    }
  ];