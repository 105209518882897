import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import {Titlesecond} from './../General.js'
import {Helmet} from "react-helmet";

class Content extends React.Component{
  constructor(props){
        super(props)
        let id=props.match.params.id
        let {global, main} = props
        this.state={
            isLoadedContent:false,
            content:null,
            idContent:id,
            domainApi:global.domainApi,
            domain:global.domain,
            apicode:global.apicode,
            history:false,
            main:main.get
        }
        this.loadcontent=this.loadcontent.bind(this)
  }

  loadcontent(){
      let paramstopass={idContent:this.state.idContent}
      paramstopass=btoa(JSON.stringify(paramstopass))
      let url =this.state.domainApi+'contentData/'+this.state.apicode+'/'+paramstopass
      fetch(url).then((response)=>{
          return response.json();
      }).then((json)=>{
          this.setState({
              isLoadedContent:true,
              content:json.content,
              history:json.history
          })
      })
  }
  componentDidMount() {
        this.loadcontent()
  }

    render(){
    if(this.state.isLoadedContent){
        let content=this.state.content
        let history=this.state.history
        let domain=this.state.domain
        let side
        let sided
        if(content.img !== null && content.img !== ""){
            side=<Col><img alt={content.title} className={'w-100'} src={domain+content.img}/></Col>
        }
        if(history && history.img !== null && history.img !== ""){
            sided=<Col><img alt={history.title} className={'w-100'} src={domain+history.img}/></Col>
        }
      return (
          <div className={'bg-white border-bottom-yellow'}>
              <Helmet>
                  <title>{content.title+' - '+this.state.main.main.name}</title>
                  <meta name="description" content={content.metadescription} />
                  <meta name="keywords" content={content.metakeywords} />
              </Helmet>
              {content.panoimg !== null ? <img alt={content.title} src={domain+content.panoimg} width={'100%'}/> : null}
              <div className={'container p-5'}>
                  <Row>
                      <Col>
                          <Titlesecond value={content.title}/>
                          <div className={'mt-3 text-justify'} dangerouslySetInnerHTML={{__html:content.content}}></div>
                      </Col>
                      {side}
                  </Row>
              </div>
              {content.id === 1 && history ?
                  <div className={'bg-light'}>
                      <div className={'container p-5'}>
                          <Row className={'py-3 '}>
                              <Col>
                                  <Titlesecond value={history.title}/>
                                  <div className={'mt-3 text-justify'} dangerouslySetInnerHTML={{__html:history.content}}></div>
                              </Col>
                              {sided}
                          </Row>
                      </div>
                  </div>
                  : null}
          </div>
      )
    }else{
      return (
          <Container className={'min-vh-100 h-100'} style={{height:'100%'}}>

            <div className={'min-vh-100 d-flex align-items-center text-center'}>
              <div className="lds-ripple ">
                <div></div>
                <div></div>
              </div>
            </div>
          </Container>
      )
    }
  }
}

export default Content;
