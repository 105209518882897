import React from 'react';
import _ from 'lodash';
import { Row, Col, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { Titlefourth, postData } from '../General';
import FormBuilder from '../Shared/Form/FormBuilder';
import { bancos, monedas } from '../resources/data';

const fields = [
    {
        label: 'Nombres',
        id: 'nombres',
        required: true,
        type: 'text',
        placeholder: 'Nombres',
        invalidMessage: 'Escriba sus nombres',
        isValid: (value) => !_.isNil(value) && value.length >= 2
    },
    {
        label: 'Primer apellido',
        id: 'primer-apellido',
        required: true,
        type: 'text',
        placeholder: 'Primer apellido',
        invalidMessage: 'Escriba su primer apellido',
        isValid: (value) => !_.isNil(value) && value.length >= 2
    },
    {
        label: 'Segundo apellido',
        id: 'segundo-apellido',
        required: true,
        type: 'text',
        placeholder: 'Segundo apellido',
        invalidMessage: 'Escriba su segundo apellido',
        isValid: (value) => !_.isNil(value) && value.length >= 2
    },
    {
        label: 'Lugar de nacimiento',
        id: 'lugar-nacimiento',
        required: true,
        type: 'text',
        placeholder: 'Lugar de nacimiento',
        invalidMessage: 'Escriba su lugar de nacimiento',
        isValid: (value) => !_.isNil(value) && value.length >= 3
    },
    {
        label: 'Estado civil',
        id: 'estado-civil',
        required: true,
        type: 'select',
        options: [
            {
                value: 'soltero (a)',
                label: 'Soltero (a)',
            },
            {
                value: 'casado (a)',
                label: 'Casado (a)',
            },
            {
                value: 'divorciado (a)',
                label: 'Divorciado (a)',
            },
            {
                value: 'viudo (a)',
                label: 'Viudo (a)',
            }
        ],
        invalidMessage: 'Seleccione su Estado civil',
        isValid: (value) => {
            return !_.isNil(value);
        }
    },
    {
        label: 'Fecha de nacimiento',
        id: 'fecha-de-nacimiento',
        required: true,
        type: 'date',
        placeholder: 'dd-mm-yyyy',
        invalidMessage: 'Escriba su fecha de nacimiento.',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Nacionalidad',
        id: 'nacionalidad',
        required: true,
        type: 'text',
        placeholder: 'Ej: Dominicano/a',
        invalidMessage: 'Escriba su nacionalidad.',
        isValid: (value) => !_.isNil(value) && value.length >= 3
    },
    {
        label: 'Género',
        id: 'genero',
        required: true,
        type: 'select',
        options: [
            {
                value: 'Masculino',
                label: 'Masculino',
            },
            {
                value: 'Femenino',
                label: 'Femenino',
            },
            {
                value: 'Otro',
                label: 'Otro',
            }
        ],
        invalidMessage: 'Seleccione su estado civil',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Cédula de identidad',
        id: 'cedula',
        required: true,
        type: 'text',
        placeholder: '000-0000000-0',
        invalidMessage: 'Escriba un número de cédula válido.',
        isValid: (value) => !_.isNil(value) && value.length >= 11
    },
    {
        label: 'Teléfono',
        id: 'telefono',
        required: true,
        type: 'text',
        placeholder: '809-555-5555',
        invalidMessage: 'Escriba un telefono válido.',
        isValid: (value) => !_.isNil(value) && value.length >= 10
    },
    {
        label: 'Fecha de expiración de cédula de identidad',
        id: 'expiracion-cedula-identidad',
        required: true,
        type: 'date',
        placeholder: 'dd-mm-yyyy',
        invalidMessage: 'Escriba la expiración de se cédula de identidad.',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Correo electrónico',
        id: 'correo',
        required: true,
        type: 'email',
        placeholder: 'ejemplo@vimenca.com',
        invalidMessage: 'Escriba un correo válido.',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Subir foto de la cédula de identidad',
        id: 'subir-foto-cedula',
        required: true,
        type: 'file',
        invalidMessage: 'Suba foto de la cédula',
        isValid: (value) => value?.length > 0
    },
    {
        label: 'Información Bancaria',
        type: 'separator',
        style: 'full-width'
    },
    {
        label: 'Bancos',
        id: 'bancos',
        required: true,
        type: 'select',
        options: bancos,
        invalidMessage: 'Seleccione el Banco',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Selecciona la moneda de tu cuenta',
        id: 'moneda',
        required: true,
        type: 'select',
        options: monedas,
        invalidMessage: 'Seleccione el Banco',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Tipo de cuenta',
        id: 'tipo-cuenta',
        required: true,
        type: 'select',
        options: [
            {
                label: 'Cuenta de ahorro',
                value: 'Cuenta de ahorro'
            },
            {
                label: 'Cuenta corriente',
                value: 'Cuenta corriente'
            },
        ],
        invalidMessage: 'Seleccione el Banco',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Número de cuenta',
        id: 'numero-de-cuenta',
        required: true,
        type: 'text',
        placeholder: '000000000',
        invalidMessage: 'Escriba un numero de cuenta válido.',
        isValid: (value) => !_.isNil(value) && value.length >= 4
    },
    {
        label: 'Certifico que todas las informaciones contenidas en este documento son veraces y que notificaré a Remesas Vimenca cualquier modificación que surgiere respecto a las mismas.',
        id: 'certifico-veracidad',
        required: true,
        type: 'checkbox',
        invalidMessage: 'Debe de estar de acuerdo con los terminos y condiciones.',
        isValid: (value) => value,
        style: 'full-width'
    }
];

function Enrolamiento(props) {
    const onSubmit = async (fieldsToSend) => {
        let url = `${props.global.domainApiV2}content/sendForm.json`;
        const formData = new FormData();
        formData.set('subject', 'Formulario de enrolamiento');
        formData.set('test', false);
        formData.set('category', 'enrolamiento_cuenta');
        Object.entries(fieldsToSend).forEach( ([property, value]) => {
            if (property === 'subir-foto-cedula') {
                formData.set(property, value.item(0));
            } else{ 
                formData.set(property, value);
            }
        });
        const header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        });
        await postData(url, formData, header);
    }
    return (
        <div className={'bg-white p-5 border-bottom-yellow'}>
            <Helmet>
                <title>{'Enrolamiento - Vimenca Western Union'}</title>
            </Helmet>
            <Row className='text-center'>
                <Col>
                    <Titlefourth value={'Formulario de enrolamiento'} />
                    <Titlefourth value={'Cuenta de Ahorro'} />
                </Col>
            </Row>
            <FormBuilder fieldsToForm={fields} onSubmit={onSubmit}/>
            <Container>
                <Row>
                    <Col>
                        <p>Como parte del proceso de confirmación de los datos, Remesas Vimenca realizará un depósito de prueba a su cuenta bancaria. Tan pronto reciba la notificación de su banco, debe comunicarse con nuestro Centro de Servicio al Cliente, llamando al número de teléfono (809) 532-7382 o al (809) 200-7380, desde el interior sin cargos, para poder empezar a recibir sus remesas a su cuenta bancaria.</p>
                        <p>Los pagos de remesas en pesos dominicanos podrán realizarse en cuentas bancarias en pesos abiertas en cualquier entidad de intermediación financiera de la República Dominicana. Las remesas internacionales en dólares podrán ser pagadas, en esta misma moneda, a través de transferencias bancarias únicamente en cuentas en dólares estadounidenses abiertas en el Banco Múltiple Vimenca, S. A., o podrán ser pagadas en moneda nacional, realizando el cambio de divisa a la tasa establecida por la entidad al momento del pago, mediante transferencia bancaria a una cuenta en pesos dominicanos abierta en cualquier entidad de intermediació financiera nacional.</p>
                        <p>El recibo remitido a su correo electrónico al momento de la realización del pago de cada remesa, el cual contiene los términos y condiciones del servicio, se constituye en formal descargo por el monto pagado. Los términos y condiciones del servicio de remesa se ponen a su disposición a través del siguiente enlace https://www.vimenca.com.do/terminos-y-condiciones/</p>
                        <p>Remesas Vimenca y Western Union no son responsables por hechos fraudulentos o ilegales asociados a las transacciones realizadas por sus clientes producto de acuerdos arribados entre ellos. Remesas Vimenca y Western Union no se involucran en los acuerdos, actividades o negocios subyacentes que pudieran existir entre remitentes y beneficiarios de remesas, ya sea que estén o no asociados al uso de nuestros servicios. Les solicitamos leer cuidadosamente nuestras políticas sobre prevención de fraudes y lavado de activos, financiamiento del terrorismo y proliferación de armas de destrucción masiva, accediendo a nuestro portal Web, en el siguiente enlace: https://www.vimencawu.com.do/</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Enrolamiento;