import React, {useEffect, useState} from 'react'

import {fetchingd, Loader, Titlesecond} from '../General'
import {Helmet} from "react-helmet";
import {ButtonGroup, Col, Row, ToggleButton} from "react-bootstrap";

const Directoacuenta = (props) => {
    const [content, setContents] = useState(null)
    const [ lang, setLang ] = useState('es');
    let main = props.main;
    const loadContent = () => {
        fetchingd('directocuenta',setContents,props.global)
    }

    useEffect(()=>loadContent(),[]);
    return <>
        { content === null ?
            <div>
                <Loader/>
            </div>
        :
            <>
                <div className={'bg-white border-bottom-yellow'}>
                    <Helmet>
                        <title>{content[lang].title+' - '+main.main.name}</title>
                        <meta name="description" content={content[lang].metadescription} />
                        <meta name="keywords" content={content[lang].metakeywords} />
                    </Helmet>
                    <div className={'mainimg'}>
                        {content[lang].panoimg !== null && <img className={'w-100'} alt={content[lang].title} src={global.domain+'/'+content[lang].panoimg}/>}
                    </div>
                    <div className={'container p-5'}>
                        <Row>
                            <Col>
                                <ButtonGroup className={'mb-3'}>
                                    <ToggleButton
                                        key={'lang1'}
                                        id={'radio-1'}
                                        type="radio"
                                        variant={'secondary'}
                                        name="radio"
                                        value={'es'}
                                        checked={lang === 'es'}
                                        onChange={(e) => setLang('es')}>
                                        Español
                                    </ToggleButton>
                                    <ToggleButton
                                        key={'lang2'}
                                        id={'radio-2'}
                                        type="radio"
                                        variant={'secondary'}
                                        name="radio"
                                        value={'en'}
                                        checked={lang === 'en'}
                                        onChange={(e) => setLang('en')}>
                                        English
                                    </ToggleButton>
                                    <ToggleButton
                                        key={'lang3'}
                                        id={'radio-3'}
                                        type="radio"
                                        variant={'secondary'}
                                        name="radio"
                                        value={'cr'}
                                        checked={lang === 'cr'}
                                        onChange={(e) => setLang('cr')}>
                                        Créole
                                    </ToggleButton>
                                </ButtonGroup>

                                <Titlesecond value={content[lang].title}/>
                                <div className={'mt-3'} dangerouslySetInnerHTML={{__html:content[lang].content}}></div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        }
        </>

}

export default Directoacuenta;
