import React from 'react'
import {Col, Container, Row, Pagination} from 'react-bootstrap'
import {Subtitle, Titlefourth} from "../General";
import Icon from 'feather-icons-react';
import {Helmet} from "react-helmet";
class Services extends React.Component{
  constructor(props){
        super(props)
        let { global, main } = props
        this.state = {
            isLoadedContent:false,
            news:null,
            domainApi:global.domainApi,
            domain:global.domain,
            apicode:global.api,
            main: main.get,
            page:1
        }
        this.changePagination=this.changePagination.bind(this)
        this.fetchData=this.fetchData.bind(this)
  }

  changePagination(value){
        this.setState({
            isLoadedContent:false,
            page:value
        })
        setTimeout(()=>this.fetchData(),1000)

  }

  fetchData(){
        let paramstopass={page:this.state.page}
        paramstopass=btoa(JSON.stringify(paramstopass))
        let url =this.state.domainApi+'servicesData/'+this.state.apicode+'/'+paramstopass
        fetch(url).then((response)=>{
            return response.json();
        }).then((json)=>{
            this.setState({
                isLoadedContent:true,
                news:json.news.items,
                pages:json.news.pages
            })
        })
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
      //this.fetchData()
  }

    render(){
    if(this.state.isLoadedContent){
        let noticias=this.state.news
        let items=[]
        let active=this.state.page
        let numberspages=this.state.pages
        if(numberspages > 1){
            for (let number = 1; number <= numberspages; number++) {
                items.push(
                    <Pagination.Item onClick={()=>{this.changePagination(number)}} key={number} active={number === active}>
                        {number}
                    </Pagination.Item>,
                );
            }
        }

        const paginationBasic = (
            <div className={'mt-3 text-xs-center'}>
                <Pagination className={'justify-content-center'} size={'lg'}>{items}</Pagination>
            </div>
        )
      return <div className="bg-white p-5 text-center border-bottom-yellow">
              <Helmet>
                  <title>{'Nuestros Servicios - '+this.state.main.main.name}</title>
              </Helmet>
              <Container>
                  <Subtitle class={'mb-3'} bg={'bgyellow'} value={'Nuestros Servicios'}/>
                  <Row className={'mt-3'}>
                      {noticias.map(function(item){
                          let linkd='/servicio/'+item.alias+'/'+item.id
                          if(item.aliaslink !== null){
                              linkd='/'+item.aliaslink
                          }
                          return <Col key={'news'+item.id} className={'text-center'} xs={12} md={'4'}>
                                  <span className={'text-black mb-3 mt-5 d-block'}>
                                    <Icon icon={item.icon} size={65}/>
                                  </span>
                                  <a className={'text-secondary'} href={linkd}>
                                      <Titlefourth class={'text-black'} value={item.title}/>
                                  </a>
                                  <p className={'mt-4'}>{item.short}</p>
                              </Col>
                      })}
                  </Row>
                  {paginationBasic}

              </Container>
          </div>
    }else{
      return <Container className={'min-vh-100 h-100'} style={{height:'100%'}}>
            <div className={'min-vh-100 d-flex align-items-center text-center'}>
              <div className="lds-ripple ">
                <div></div>
                <div></div>
              </div>
            </div>
          </Container>
    }
  }
}

export default Services;
