import React, {useEffect, useRef, useState} from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import { fetchData, Titlesecond, Subtitle } from "../General";

const FaqsByCategory = (props) => {
    const [content, setContent] = useState();
    const [showFaqs, setShowFaqs] = useState('none');
    const faqs = useRef(null);

    const executeScroll = () => faqs.current.scrollIntoView({behavior: 'smooth'})
    const { id, global } = props
    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/faqs-by-category.json?catid=${id}`, setContent)
    },[])
    return <div>{content && <Card ref={faqs} id={'faqs'} className={'mt-5'}>
        <Card.Header>
            <p className='m-0'>Preguntas Frecuentes</p>
        </Card.Header>
        <Card.Body>
            <div>
                {content.get.faqs.map( (f,i) => (
                    <div key={'faqs'+i} className={'mt-4'}>
                        <Subtitle value={ (i+1) + '. ' + f.question}/>
                        <div dangerouslySetInnerHTML={{__html:f.awnser}}></div>
                    </div>
                    )
                )}
            </div>
        </Card.Body>
    </Card>}
    </div>
}

export default FaqsByCategory;