import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Titlesecond} from "../General";
import {Helmet} from "react-helmet";
import terminos from './../resources/documents/TerminosCondiciones_SimpleYRapido.pdf'

const blocks = [
    {
        title: 'Comunícate con nuestro Centro de Servicio al Cliente al 1(809)532-7382',
        body: 'Selecciona el idioma deseado, luego marca la opción enviar o recibir dinero por teléfono y suministra los datos de tu envío.'
    },
    {
        title: 'Visita la localidad de Western Union en Vimenca y PagaTodo más cerca de ti',
        body: 'Informa tu número de PIN y confirma tus datos.'
    },
    {
        body: 'Deposita o retira tu dinero en efectivo y obtén tu recibo.'
    }
];

const description = 'Es un servicio de Western Union en Vimenca, mediante el cual los clientes inician sus transacciones de envíos y pagos de remesas por teléfono, a través del Centro de Servicio al Cliente y posteriormente culminan el proceso en un punto de venta.';
export default function SimpleRapido(props) {
    return (
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{'Simple y Rápido'}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={'simple y rapido, vimenca'} />
            </Helmet>
            <div className={'container p-5 text-center'}>
                <Row>
                    <Col>
                        <Titlesecond value={'Simple y rápido'}/>
                        <p className={'p-5'}>{description}</p>
                        <p className='mb-5'><a href={terminos}>Descarga los términos y condiciones</a></p>

                        <Titlesecond value={'¿Cómo funciona?'}/>
                        <Row className={'my-5'}>
                            {blocks.map(block =>
                                <Col>
                                    <div 
                                        className={'bgyellow mx-3 text-center card card-body d-flex align-items-center p-5'}
                                        style={{height: 280}}
                                    >
                                        { block.title && <p><strong>{block.title}</strong></p>}
                                        <p className={'m-0'}>{block.body}</p>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
