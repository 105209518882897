import React, {useEffect, useState} from 'react'
import {Carousel, Row, Col} from 'react-bootstrap'
import {Loader,fetching} from './../General'

import '../resources/bootstrap2/css/bootstrap.min.css'
import '../vimenca2.css'
import titleImg from '../resources/img/Title.png';
import phoneImg from '../resources/img/Phone_Vimancash.png';
import logoVimencash from '../resources/img/Group 309.png';
import tarjetaPrepago from '../resources/img/imgs/Group 348.png';
import tarjetaPrepagoMobile from '../resources/img/imgs/Group 366.png';
import tituloCrear from '../resources/img/imgs/Group 60.png';
import appStore from '../resources/img/imgs/Group 317.png';
import playStore from '../resources/img/imgs/Group 318.png';
import wuLogo from '../resources/img/imgs/Logo_WU.png';
import vimencaLogo from '../resources/img/imgs/Group 156.png';
import nextIcon from '../resources/img/imgs/Group 108.png';
import prevIcon from '../resources/img/imgs/Group 114.png';
import vimencashTitulo from '../resources/img/imgs/Group 340.png';
import phoneStage1 from '../resources/img/phonestages/Cellphone Stage 1.png';
import phoneStage2 from '../resources/img/phonestages/Cellphone Stage 2.png';
import phoneStage3 from '../resources/img/phonestages/Cellphone Stage 3.png';
import phoneStage4 from '../resources/img/phonestages/Cellphone Stage 4.png';

const Vimencash = (props) => <Vimencashcontent {...props} />

const Videos = props => {
    const [videoID,setVideoID] = useState(null)
    const [loading,setLoading] = useState(true)
    const [loadedVideo,setLoadedVideo] = useState(false)
    const [videos,setVideos]=useState(null)

    const loadVideos = () => {
        let url = 'vimencashData'
        fetching(url,setVideos,props)
        setLoading(false)
    }

    const settingVideo = (youtubeid) => {
        setLoadedVideo(false)
        setVideoID(youtubeid)
        setLoadedVideo(true)
    }

    if(videos !== null && videos.videos.length > 0 && videoID === null){
        settingVideo(videos.videos[0].youtube_video_id)
    }

    useEffect(()=>loadVideos(),[])

    return <>
            {loading === true
                ?
                <Loader/>
                :
                <>
                    {videos !== null && videos.videos.length > 0 &&
                        <div className="py-5">
                            <img src={tituloCrear} className="mt-5" />
                        </div>
                    }
                    <Row className={'justify-content-center align-items-center mt-3'}>
                        <Col lg={3} md={4} sm={12}>
                            <div className={'d-grid gap-2'}>
                                {videos !== null
                                    ?
                                    videos.videos.map(item =>{
                                        return <button className={item.youtube_video_id === videoID ? 'text-yellow w-100 btn mb-3 bg-blue-main font-bold rounded-btn' : 'w-100 btn mb-3 bg-yellow-main font-bold rounded-btn'}
                                                       style={{fontSize:'1.25em'}}
                                                       //variant={item.youtube_video_id == videoID ? 'secondary' : 'light'}
                                                       key={'itemvid'+item.youtube_video_id}
                                                       onClick={()=>settingVideo(item.youtube_video_id)}>
                                            <span className={item.youtube_video_id === videoID ? 'text-yellow py-1 m-0 d-block' : 'text-blue py-1 m-0 d-block'}>{item.titulo}</span>
                                        </button>})
                                    :
                                    null
                                }
                            </div>
                        </Col>
                        <Col lg={6} md={8} sm={12}>
                            {loadedVideo
                                ?
                                <iframe width="100%" height="500" src={"https://www.youtube.com/embed/"+videoID+"?controls=0"}
                                        title="YouTube video player" frameBorder="0"
                                        className={'mt-sm-4 mt-md-0'}
                                        style={{maxWidth: 1750}}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                :
                                null
                            }
                        </Col>
                    </Row>
                </>
                    }
           </>
}

class Vimencashcontent extends React.Component {

    constructor(props){
        super(props)
        this.state={
            props:props
        }
    }

    render() {
        return <div className="vimencash">
                <div className="vimencash-hero h-full d-none d-md-flex">
                    <div className="container">
                        <img src={titleImg} className="" />
                    </div>

                </div>
                <div className="bg-blue-main text-center d-md-none">
                    <img src={titleImg} className="my-5 p-2" />

                    <img src={phoneImg} className="d-md-none phoneimg" />

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <polygon fill="#FFDD00" points="0,100 0,0 100,50" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <polygon fill="#0055B8" points="100,100 0,0 100,0" />
                </svg>

                <div style={{ background: "white" }}>
                    <div style={{ position: 'relative' }}>
                        <svg style={{ bottom: 0 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" className="position:relative">
                            <polygon fill="#023E70" points="100,0 100,100 0,100" />
                        </svg>
                        <svg style={{ bottom: 0 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <polygon fill="#0055B8" points="100,100 0,100 0,0" />
                        </svg>
                        <div className="container-xl" >
                            <div className="d-md-none py-5 "></div>
                            <div className="d-md-none py-5 "></div>
                            <div className="row ustify-content-between">
                                <div className="col-md-4 align-self-center text-center -pt-5">
                                    <img src={phoneImg} className=" d-none d-md-block" />
                                </div>
                                <div className="col-md-8  align-self-center text-xl">
                                    <h2 className="hnco text-uppercase text-blue">¿Qué es <img className="d-inline-block vimencashlogo" src={logoVimencash} />?</h2>
                                    <h4 className="py-5">
                                        Vimencash es una aplicación móvil de servicios múltiples con la que podrás disfrutar de las remesas que recibes en Vimenca y Western Union al instante, con el objetivo de facilitar tus diligencias habituales de forma ágil, rápida y segura.
                                    </h4>
                                    <h4 className="pb-5">
                                        Al crear tu usuario en Vimencash, automáticamente te habilitamos una Tarjeta Prepago, para tus consumos.
                                    </h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="bg-blue-main" style={{ position: 'relative' }}>

                    <div className=" ">
                        <div className=" text-center py-5">
                            <img src={vimencashTitulo} className="mx-auto d-none d-md-block" />
                        </div>
                        <div className="container">
                            <Carousel
                                controls={true} fade={true} nextIcon={<img src={nextIcon} />} prevIcon={<img src={prevIcon} />} >

                                <Carousel.Item interval={3500}>

                                    <div className='bannercontainer videobanner'>
                                        <img className={'bannerimg'} src={phoneStage1} />
                                    </div>


                                </Carousel.Item>
                                <Carousel.Item interval={3500}>

                                    <div className='bannercontainer videobanner'>
                                        <img className={'bannerimg'} src={phoneStage2} />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={3500}>

                                    <div className='bannercontainer videobanner'>
                                        <img className={'bannerimg'} src={phoneStage3} />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item interval={3500}>

                                    <div className='bannercontainer videobanner'>
                                        <img className={'bannerimg'} src={phoneStage4} />
                                    </div>
                                </Carousel.Item>


                            </Carousel>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon fill="#023E70" points="0,100 0,0 100,50" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon fill="#0055B8" points="100,100 0,0 100,0" />
                    </svg>
                </div>

                <div style={{ background: "white" }}>

                    <div className="py-5" style={{ position: "relative" }}>
                        <div className="container py-5 hidden d-none d-md-block">
                            <img src={tarjetaPrepago} className="my-5" />
                        </div>
                        <div className="container py-5 d-md-none text-center">
                            <img src={tarjetaPrepagoMobile} className="my-5" />
                        </div>
                        <svg style={{ bottom: 0 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" className="position:relative">
                            <polygon fill="#023E70" points="100,0 100,100 0,100" />
                        </svg>
                        <svg style={{ bottom: 0 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <polygon fill="#0055B8" points="100,100 0,100 0,0" />
                        </svg>
                    </div>
                </div>

                <div style={{position:'relative'}}>

                    <div className="d-flex  py-5" style={{ background: '#0055B5' }}>
                        <div className="p-5 container text-white text-center">
                            <div className="d-none d-md-block">
                                <h2 className="hnco ">PUEDES RETIRAR TU TARJETA PREPAGO EN CUALQUIER SUCURSAL DE BANCO VIMENCA, REMESAS VIMENCA Y VIMENPAQ</h2>
                            </div>
                            <div className=" d-md-none">
                                <h4 className="hnco">PUEDES RETIRAR TU TARJETA PREPAGO EN CUALQUIER SUCURSAL DE BANCO VIMENCA, REMESAS VIMENCA Y VIMENPAQ</h4>
                            </div>
                            <div className=" my-5 text-center ">
                                <a href="https://vimencawu.com.do/oficinas-vimencash" className=" my-5 bg-yellow-main text-blue sucursalbtn rounded-btn text-xl">Ver Sucursales</a>
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{ background: "white", position: 'relative' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon fill="#023E70" points="0,100 0,0 100,50" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon fill="#0055B8" points="100,100 0,0 100,0" />
                    </svg>
                    <div className="p-5 text-center" >

                        <Videos props={this.state.props} />

                        <div className="py-5 my-5"></div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ bottom: -1 }} viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon fill="#FFDD00" points="0,0 100, 100 0 ,100" />
                    </svg>
                </div>

                <div style={{ background: "#FFDD00" }}>
                    <div className="container py-5 text-center text-xl text-blue font-bold hnco ">
                        <h1 className="hnco ">¡Descárgala Gratis!</h1>
                        <div>

                            <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.vimenca.vimenca_app">
                                <img src={playStore} style={{ maxWidth: '250px', height:84 }} className="p-2 d-inline-block" />
                            </a>
                            <a target={'_blank'} href="https://apps.apple.com/es/app/vimencash/id1540865910">
                                <img src={appStore} style={{ maxWidth: '250px', height:84 }} className="p-2 d-inline-block" />
                            </a>
                        </div>

                        <div className={'mt-5'}>
                            <a className={'text-blue'} href={'/'}>Volver al inicio</a>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon fill="#FFDD00" points="0,100 0,0 100,0" />
                    </svg>
                </div>

                <div style={{ background: "white" }}>
                    <div className="py-5 p-md-5 ">
                        <div className="p-md-5">
                            <div className=" p-5 mx-md-5 ">
                                <div className="d-md-flex justify-content-between align-items-center text-center">
                                    <div className="mt-5 mt-md-0">
                                        <img src={wuLogo} className="" style={{ maxWidth: '250px' }} />
                                    </div>
                                    <div className={'mt-5 mt-md-0'}>
                                        <img src={vimencaLogo} className="" style={{ maxWidth: '250px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ background: "#fff" }}>
                    <div className="container text-center py-3 hnco " style={{ color: "#000" }}>
                        <p className="d-none d-md-block">Todos los logotipos, marcas comerciales, marcas de servicio y nombres comerciales a los que se hace referencia en este material son propiedad de sus respectivos dueños.</p>
                        <h5 className="d-md-none">Todos los logotipos, marcas comerciales, marcas de servicio y nombres comerciales a los que se hace referencia en este material son propiedad de sus respectivos dueños.</h5>
                    </div>
                </div>
            </div>
    }
}


export default Vimencash;
