import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { Titlefourth, postData } from '../General';
import FormBuilder from '../Shared/Form/FormBuilder';
import { monedas } from '../resources/data';

const fields = [
    {
        label: 'Nombre',
        id: 'nombre',
        required: true,
        type: 'text',
        placeholder: 'Nombre completo',
        invalidMessage: 'Escriba su nombre',
        isValid: (value) => !_.isNil(value) && value.length >= 2
    },
    {
        label: 'Cédula de identidad',
        id: 'cedula',
        required: true,
        type: 'text',
        placeholder: '000-0000000-0',
        invalidMessage: 'Escriba una cédula válida. Esta permitido 11 dígitos y guiones.',
        isValid: (value) => !_.isNil(value) && value.length >= 11 && /^[0-9-]*$/.test(value)
    },
    {
        label: 'Teléfono',
        id: 'telefono',
        required: true,
        type: 'text',
        placeholder: '809-555-5555',
        invalidMessage: 'Escriba un teléfono válido. Esta permitido 11 dígitos, guiones y paréntesis.',
        isValid: (value) => !_.isNil(value) && value.replace(/[-()]+/g, '').length >= 10 && /^[0-9-()\s]*$/.test(value)
    },
    {
        label: 'Celular',
        id: 'celular',
        required: true,
        type: 'text',
        placeholder: '809-444-4444',
        invalidMessage: 'Escriba un celular válido. Esta permitido 11 dígitos, guiones y paréntesis.',
        isValid: (value) => !_.isNil(value) && value.replace(/[-()]+/g, '').length >= 10 && /^[0-9-()\s]*$/.test(value)
    },
    {
        label: 'Selecciona la moneda para aperturar tu cuenta',
        id: 'moneda-seleccionada',
        required: true,
        type: 'select',
        options: monedas,
        invalidMessage: 'Seleccione una moneda',
        isValid: (value) => !_.isNil(value)
    },
    {
        label: 'Acepto compartir esta información con el banco y garantizo la veracidad de la misma.',
        id: 'acepto-compartir',
        required: true,
        type: 'checkbox',
        invalidMessage: 'Debe de estar de acuerdo con los terminos y condiciones.',
        isValid: (value) => value
    }
];
function Apertura(props) {
    const onSubmit = async (fieldsToSend) => {
        let url = `${props.global.domainApiV2}content/sendForm.json`;
        const formData = new FormData();
        formData.set('test', false);
        formData.set('subject', 'Formulario de Apertura Cuenta Bancaria');
        formData.set('category', 'apertura_cuenta');
        // formData.set('Tipo de mensaje', 'Formulario de Registro Cuenta Bancaria (no Ahorro Simplificada)');
        Object.entries(fieldsToSend).forEach( ([property, value]) => {
            formData.set(property, value)
        });
        const header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        });
        await postData(url, formData, header);
    }
    return (
        <div className={'bg-white py-5 border-bottom-yellow'}>
            <Helmet>
                <title>{'Apertura - Vimenca Western Union'}</title>
            </Helmet>
            <Container>
                <Row className='text-center'>
                    <Col>
                        <Titlefourth value={'Formulario de registro'} />
                        <Titlefourth value={'Cuenta de Ahorro'} />
                    </Col>
                </Row>
            </Container>
            <FormBuilder fieldsToForm={fields} onSubmit={onSubmit} />
        </div>
    );
};

export default Apertura;