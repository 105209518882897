import React from 'react'
import { IframeOficinas } from './IframeOficinas';

const VimencashOficinas = ( props) =>{
    return (
        <div>
            <IframeOficinas {...props} />
        </div>
    );
}

export default VimencashOficinas;