import React from 'react'
import { Form } from 'react-bootstrap';
import { AllFieldPropsType } from './types';

function SingleCheckboxForm(props: AllFieldPropsType): any{
    const {label, id, invalidMessage, isValid, required } = props.properties;
    const valid = isValid(props.fields[id]);
    const message = !valid && invalidMessage;
    const { isFormValidated } = props;
    return (
        <Form.Group className="mb-3" controlId={id}>
            <Form.Label></Form.Label>
            <Form.Check // prettier-ignore
                type={'checkbox'}
                id={id}
                label={label}
                required={required}
                isInvalid={props.isFormValidated ?!valid : undefined}
            >
                <Form.Check.Input 
                    className={!valid && isFormValidated ? 'border-danger bg-danger' : undefined}
                    type={'checkbox'} 
                    isValid={valid} 
                    onChange={props.onChange}
                />
                <Form.Check.Label
                    className={!valid && isFormValidated ? 'text-danger' : undefined}
                >{label}</Form.Check.Label>
                <Form.Control.Feedback type={ valid ? 'valid' : 'invalid' }>
                    {message}
                </Form.Control.Feedback>
            </Form.Check>
        </Form.Group>
    );
}

export default SingleCheckboxForm;