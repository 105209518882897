import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Titlesecond, Loader, fetchData} from "../General";
import {Helmet} from "react-helmet";
import FaqsByCategory from './FaqsByCategory';

const Promo = (props) => {
    let {global, main}=props
    const [content, setContent] = useState(null)

    const faqs = useRef(null);

    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/view.json?id=53`, setContent)
    },[])
    
    return <>
        {content !== null ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{`${content.content.title} - Vimenca Western Union`}</title>
                <meta name="description" content={content.content.metadescription} />
                <meta name="keywords" content={content.content.metakeywords} />
            </Helmet>
            <div className={'mainimg border-bottom'}>
                <img className={'w-100'} alt={content.content.title} src={global.domain+'/'+content.content.panoimg} />
            </div>
            <div className={'container p-5'}>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12} lg={8}><h3 className='mt-0 mb-4'>{content.content.title}</h3></Col>
                            <Col xs={12} lg={4}>
                                <a href={'/faqs?id=11'} className={'py-2 float-md-right mt-2 btn btn-primary'} >Preguntas Frecuentes</a>
                            </Col>
                            <Col xs={12}>
                                <div className={'mt-3'} dangerouslySetInnerHTML={{__html:content.content.content}}></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
        : <Loader />
        }
        </>

}

export default Promo;
