import React,{ useState, useEffect } from 'react'
import {Col, Row, Form} from 'react-bootstrap'
import { Titlesecond,Subtitle, Loader, fetchData } from './../General.js'
import {Helmet} from "react-helmet"

const Fields = (props) => {
    const [loadForm , setLoadForm]=useState(true);
    const [error , setError]=useState(false);
    const [buttonvalue , setButtonValue]=useState(true);
    const [loading , setLoading]=useState(false);
    let disponibilidades = props.state.content.disponibilidad;
    
    const validateFields = () => {
        let form = document.getElementById('submittalento')
        let inputs = form.getElementsByTagName("input")
        let allfield = 0
        let selects = form.getElementsByTagName("select")
        let space = document.getElementById('validationspace')

        for (var i = 0; i < inputs.length; i++) {
            let value=inputs[i].value
            let required=inputs[i].required
            if(value === "" && required){
                allfield++
                inputs[i].classList.add("empty")
            }
            if(value !== "" && required){
                inputs[i].classList.remove("empty")
            }
        }

        for (var a = 0; a < selects.length; a++) {
            let value=selects[a].value
            let required=selects[a].required
            if(value === "" && required){
                allfield++
                selects[a].classList.add("empty")
            }
            if(value !== "" && required){
                selects[a].classList.remove("empty")
            }
        }

        if(allfield > 0){
            setButtonValue(true)
            space.innerHTML='<div class="alert alert-sm alert-warning"><p class="m-0"><small>Debe de llenar todos los campos para poder enviar la solicitud.</small></p></div>'
        }else{
            space.innerHTML=''
            setButtonValue(false)
        }
        return

    }

    const sendForm = () => {
        setLoading(true)
        let fields=document.forms.submittalento
        let tosend=new FormData(fields);

        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        });

        let sentData={
            method:'POST',
            mode: 'cors',
            header: header,
            body: tosend
        }

        let url =props.state.domainApi+'talentosSend'
        fetch(url, sentData)
            .then(response=>response.text())
            .then(json=> {
                setLoading(false)
                if(json === "true"){
                    setLoadForm(false)
                    setError(false)
                }else{
                    setLoadForm(true)
                    setError(<div className={'alert alert-info'}><p className={'mb-0'}>Hubo un error en el envío. Intente de nuevo.</p></div>)
                }
            })
    }
    return (
    <>{ loadForm ? (
        <div className={'text-left'}>
            <Form id={'submittalento'}>
                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <Form.Group controlId="nombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control name={'nombre'} required={true} onBlur={validateFields} type="nombre" placeholder="Escriba su Nombre" />
                        </Form.Group>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <Form.Group controlId="apellido">
                            <Form.Label>Apellido</Form.Label>
                            <Form.Control name={'apellido'} required={true} onBlur={validateFields} type="apellido" placeholder="Escriba su Apellido" />
                        </Form.Group>
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-12 col-md-6'}>
                        <Form.Group controlId="cedula">
                            <Form.Label>Cédula</Form.Label>
                            <Form.Control name={'cedula'} required={true} onBlur={validateFields} type="cedula" placeholder="Escriba su Cédula" />
                        </Form.Group>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <Form.Group controlId="nivelacademico">
                            <Form.Label>Nivel Académico</Form.Label>
                            <Form.Control name={'nivelacademico'} required={true} onBlur={validateFields} defaultValue={'Bachiller'} as="select">
                                <option value={'Inicial'}>Inicial</option>
                                <option value={'Básico'}>Básico</option>
                                <option value={'Intermedio'}>Intermedio</option>
                                <option value={'Bachiller'}>Bachiller</option>
                                <option value={'Técnico'}>Técnico</option>
                                <option value={'Universitario'}>Universitario</option>
                                <option value={'Maestría / Postgrado'}>Maestría / Postgrado</option>
                                <option value={'Doctorado'}>Doctorado</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-12'}>
                        <Form.Group controlId="nombre">
                            <Form.Label>Disponibilidad de horario</Form.Label>
                            <div>
                            {disponibilidades.map(item=>{
                                return (<Form.Check
                                    name={'disponibilidad[]'}
                                    inline
                                    value={item.name}
                                    type={'checkbox'}
                                    id={'disponibilidad-'+item.id}
                                    key={'disponibilidad-'+item.id}
                                    label={item.name} />)
                            })}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-12 col-md-6'}>
                        <Form.Group controlId="lugarresidencia">
                            <Form.Label>Lugar o zona de residencia</Form.Label>
                            <Form.Control name={'lugarderesidencia'} required={true} onBlur={validateFields} type="lugarresidencia" placeholder="Escriba su Lugar o zona de residencia" />
                        </Form.Group>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Correo Electrónico</Form.Label>
                            <Form.Control name={'email'} required={true} onBlur={validateFields} type="email" placeholder="Escriba su Correo Electrónico" />

                        </Form.Group>
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-12 col-md-6'}>
                        <Form.Group controlId="telefono">
                            <Form.Label>Teléfono de contacto</Form.Label>
                            <Form.Control name={'telefono'} required={true} onBlur={validateFields} type="text" placeholder="Escriba su Teléfono de contacto" />
                        </Form.Group>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <Form.Label>Subre tu CV</Form.Label>
                        <Form.Control 
                            type={'file'}
                            name={'cv'}
                            className={'form-control p-0 '} 
                            required={true} 
                            onChange={()=>null}
                            id="archivo"
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        {error ? error : null}
                        <div id={'validationspace'}></div>
                        {!loading ? <button disabled={buttonvalue} type={"button"} onClick={()=>sendForm()} className={'btn btn-primary mt-3'}>Enviar</button> : <Loader/>}
                        <p className={'mt-3'}><small>Esta información es de uso exclusivo para Agente de Remesas y Cambio Vimenca, S.A.</small></p>
                    </div>
                </div>
            </Form>
        </div>) : 
        (<div className='alert alert-warning'>Hemos recibido su solicitud, muy pronto lo estaremos revisando.</div>)
        }</>);
}
export default function Talentos(props){
    const [ content, setContent ] = useState();
    const [ isLoadedContent, setIsLoadedContent ] = useState(true);

    useEffect(() => {
        async function loadData(){
            let url = props.global.domainApi+'talentosData/'+props.global.apicode
            await fetchData(url, setContent);
            setIsLoadedContent(false);
        }
        loadData();
    }, []);

    return !isLoadedContent ? (
              <div className={'bg-white text-center border-bottom-yellow'}>
                  <Helmet>
                      <title>{content.content.title+' - '+props.main.name}</title>
                      <meta name="description" content={content.content.metadescription} />
                      <meta name="keywords" content={content.content.metakeywords} />
                  </Helmet>
                  <div className={'mainimg'}>
                    <img src={props.global.domain+"/"+content.content.panoimg || 'img/hands-with-money-at-bank-or-currency-exchanger-PX9HLSU.jpg'} alt={content.content.title}/>
                  </div>
                  <Row className={'mt-5 px-5'}>
                      <Col className={'text-center'} xs={12}>
                          <Subtitle bg={'bg-black'} fontcolor={'text-white'} value={'Talentos'}/>
                          <Titlesecond class={'mt-3'} value={content.content.title} />
                          <div className="text-center" dangerouslySetInnerHTML={{__html:content.content.content}}></div>
                      </Col>
                  </Row>
                  <div className={'container p-5'}>
                        <Fields state={
                            {
                                isLoadedContent,
                                content,
                                domainApi: props.global.domainApi,
                                domain: props.global.domain,
                                csfrtoken: props.global.csfrtoken,
                                apicode: props.global.apicode,
                                localidades:[],
                                page: 1,
                                main: props.main
                            } 
                        }
                        />
                  </div>
              </div>
        ) : <Loader />    
}