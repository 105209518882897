import React from 'react'
import {Col, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

import monCashBanner from './../resources/img/monCash/bannerMonCash.png'

import monCashArte from './../resources/img/monCash/monCashArte.png'

const MonCash = props  => {
    let { main } = props
    return (
       <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{'SPM  - '+main.get.main.name}</title>
            </Helmet>
            <img alt={"MON cash"} src={monCashBanner} />
            <div className={'container p-5 text-center'}>
                <Row>
                    <Col>
                        <a href='/oficinas'><img alt={"MON cash"} src={monCashArte} /></a>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default MonCash;
